import React from "react"
import styled, {createGlobalStyle} from "styled-components"
import photoImage from '../images/photo.png'
import skillsIcon from '../images/skills.svg'
import experienceIcon from '../images/experience.svg'
import educationIcon from '../images/education.svg'
import languagesIcon from '../images/languages.svg'
import interestsIcon from '../images/interests.svg'

const GlobalStyle = createGlobalStyle`
	body {
		font-family: gothic;
		font-size: 14px;
		background: #DDD;
		margin: 0;
		color: #002E58;
		-webkit-print-color-adjust: exact;
		@media print {
			background: inherit;
		}
	}
	@page {
		margin: 1.8cm;
	}

	p {
		text-align: justify
	}
`;

const PrintableBodyWrapper = styled.div`

`;
// const PrintableBodyWrapper = styled.div`
//   @media print {
//     position: fixed;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     background-color: black;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
// `;

const Container = styled.div`
	max-width: 21cm;
	background-color: #FFF;
	box-shadow: 0 0 10px rgba(88,88,95,0.5);
	padding: 2cm;
	margin: auto;
	position: relative;

	@media print {
		width: 100%;
		height: 100%;
		max-width: none;
		box-shadow: none;
		padding: 0;
	  }
`

const Section = styled.section`
	width: 100%;
	position: relative;

	@media print {
		break-inside: avoid;
	}
`

const Name = styled.h1`
  margin: 0;
  font-size: 40px;
`

const JobTitle = styled.h3`
  font-weight: normal;
  margin-top: 0.5em;
  margin-bottom: 0;
`

const Photo = styled.div`
	width: 10.5em;
	height: 10.5em;
	border-radius: 100%;
	overflow: hidden;
	position: absolute;
	right: 2em;
	top: 0;
`

const StyledImage = styled.img`
width: 100%;
height: 100%;
`

const SectionHeader = styled.div`
	display: flex;
	align-items: center;
	margin-top: 0.3em;
`

const SectionTitle = styled.h5`
	margin: 0;
	margin-left: 1em;
	font-size: 18px;
`

const SectionIcon = styled.div`
  width: 1.3em;
  height: 1.3em;
  background: #002E58;
  padding: 4px;
  border-radius: 0.5em;
  float: left;
`

const SectionContent = styled.div`
	margin-left: 10em;
`

const List = styled.ul`
	display: flex;
	flex-wrap: wrap;
	padding-left: 16px;
`

const ListItem = styled.li`
	text-align: ${props => (!props.col || props.col === "1") ? 'justify' : 'left'};
	flex: 0 0 auto;
	padding-right: 32px;
	width: ${props => `calc(${100 / parseInt(props.col || 1)}% - 32px)`}
`

const Left = styled.div`
	position: absolute;
	left: 0;
	margin-top: 0.5em;
	width: 9em;
`

const Exp = styled.div`
	margin-top: 1em;
`

const ExpTitle = styled.h4`
	margin: 0;
	font-size: 18px;
`
const ExpPlace = styled.h6`
	margin: 0;
	font-size: 14px;
`

export default function Resume() {
  return (
	<React.Fragment>
		<GlobalStyle/>
		<PrintableBodyWrapper>
			<Container>
				<Section>
					<Photo><StyledImage src={photoImage}/></Photo>
					<Name>Agustín Caire</Name>
					<JobTitle>Backend Developer</JobTitle>
					<p><b>Dirección</b>&nbsp;&nbsp;Concepción Del Uruguay, Entre Ríos, Argentina<br/>
					<b>Teléfono</b>&nbsp;&nbsp;+54 9 3442 539903<br/>
					<b>E-mail</b>&nbsp;&nbsp;agustincaire@gmail.com</p>
				</Section>
				<Section>
					<p>Desarrollador especializado en backend enfocado en la resolución de problemas y creación de soluciones eficientes e innovadoras utilizando tecnologías de vanguardia. Cuento con experiencia en desarrollo de APIs utilizando una variada gama de bases de datos SQL/NoSQL e integración con servicios cloud</p>
				</Section>
				<Section>
					<SectionHeader>
						<SectionIcon><img width="100%" height="100%" src={skillsIcon}/></SectionIcon>
						<SectionTitle> Skills</SectionTitle>
					</SectionHeader>
					<SectionContent>
						<List>
							<ListItem col="2">Node.Js</ListItem>
							<ListItem col="2">REST API</ListItem>
							<ListItem col="2">MySQL / PostgreSQL</ListItem>
							<ListItem col="2">MongoDB</ListItem>
							<ListItem col="2">HTML / CSS</ListItem>
							<ListItem col="2">Python</ListItem>
							<ListItem col="2">Docker</ListItem>
							<ListItem col="2">Kubernetes</ListItem>
						</List>
					</SectionContent>
				</Section>
				<Section>
					<SectionHeader>
						<SectionIcon><img width="100%" height="100%" src={experienceIcon}/></SectionIcon>
						<SectionTitle> Experiencia Laboral</SectionTitle>
					</SectionHeader>
					<SectionContent>
						<Exp>
							<Left>2019-01 - Actualidad</Left>
							<ExpTitle>Backend Developer</ExpTitle>
							<ExpPlace>Asimov S.R.L.</ExpPlace>
							<List>
								<ListItem>Rediseño de modelo de datos y API de sitio web adaptandolo a nueva tecnología de base de datos logrando una reducción del costo de mantenimiento</ListItem>
								<ListItem>Diseño de arquitectura y desarrollo de APIs para sistema modular basado en micro servicios incluyendo integración con API de Auth0 y deploy en AWS</ListItem>
								<ListItem>Desarrollo de API para sitio web de e-commerce incluyendo gestión de pagos a través de la integración con Mercado Pago</ListItem>
								<ListItem>Implementación de cluster Kubernetes para ejecutar aplicaciones de gestión de tareas, repositorios y CI / CD de la empresa</ListItem>
								{/* <ListItem></ListItem> */}
							</List>
						</Exp>
					</SectionContent>
				</Section>
				<Section>
					<SectionHeader>
						<SectionIcon><img width="100%" height="100%" src={educationIcon}/></SectionIcon>
						<SectionTitle> Educación</SectionTitle>
					</SectionHeader>
					<SectionContent>
						<Exp>
							<Left>2014-01 - 2019-06</Left>
							<ExpTitle>Ingeniería en Sistemas de Información</ExpTitle>
							<ExpPlace>Universidad Tecnológica Nacional</ExpPlace>
						</Exp>
					</SectionContent>
				</Section>
				<Section>
					<SectionHeader>
						<SectionIcon><img width="100%" height="100%" src={languagesIcon}/></SectionIcon>
						<SectionTitle> Lenguajes</SectionTitle>
					</SectionHeader>
					<SectionContent>
						<List>
							<ListItem>Ingles: intermedio (escrito)</ListItem>
						</List>
					</SectionContent>
				</Section>
				<Section>
					<SectionHeader>
						<SectionIcon><img width="100%" height="100%" src={interestsIcon}/></SectionIcon>
						<SectionTitle> Intereses</SectionTitle>
					</SectionHeader>
					<SectionContent>
						<List>
							<ListItem col="2">Participar en proyectos innovadores</ListItem>
							<ListItem col="2">Formar parte de un equipo dinámico</ListItem>
							<ListItem col="2">Ampliar mis áreas de conocimiento</ListItem>
							<ListItem col="2">Ampliar mi nivel de ingles</ListItem>
						</List>
					</SectionContent>
				</Section>
			</Container>
		</PrintableBodyWrapper>
	</React.Fragment>

  )
}